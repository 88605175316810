import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addAnimal, uploadImage, uploadSingleFile, addDocumentToAnimal } from '../../features/animals/animalSlice';
import { createAndSendNotification } from '../../features/notifications/notificationSlice';
import { fetchAnimalsByCanal } from '../../features/animals/animalSlice';
import CityAndSectorSelect from '../../components/citiesSector/CityAndSectorSelect';
import ColorSelect from '../../components/animals/ColorSelect';
import Swal from 'sweetalert2';
import 'animate.css';
import iconpaw from '../../assets/icon-paw.svg';
import iconadd from '../../assets/icon-add.svg';
import imgIcon from '../../assets/image-outline.svg'
import imgIconWhite from '../../assets/image-outline-white.svg'
import BackButton from '../../components/general/BackButton';
import LoadingPage from '../../components/general/LoadingPage';
import MotherSelect from '../../components/animals/MotherAnimalSelect';
import RaceSelect from '../../components/animals/RaceSelect';
import HairSelect from '../../components/animals/HairSelect';
import OpenableSection from '../../components/documentation/OpenableSection';
import SituationSelect from '../../components/animals/SituationCatSelect';
import ImageAnalyzer from '../../components/general/ImageUploader';
import { RACES } from '../../data/races';
import { HAIRS } from '../../data/hair';
import { COLORS } from '../../data/colors';
import { PATTERNS } from '../../data/colorsPattern.js';
import { Tooltip } from 'react-tooltip';
import helpOutline from '../../assets/help-outline.svg';
import { useTranslation } from 'react-i18next';
import CatNotFound from '../../assets/cat-not-found-white.png'
import { fetchSubSectors } from '../../features/citiesSector/subSectorSlice';
import PatternSelect from '../../components/animals/BreadSelect';
import AssociationSelect from '../../components/animals/AssociationSelect.jsx';
import { fetchAssociationsDetails, fetchCanalsAssociation } from '../../features/association/associationSlice.js';
import DynamicMap from '../Maps/standardMap.js';


const ObjectForm = () => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const { uid } = useSelector((state) => state.auth);
    const { t } = useTranslation();
    const { canalId } = useParams();
    const { data: animals } = useSelector((state) => state.animals)
    const today = new Date();
    const formattedTodayDate = today.toISOString().split('T')[0];
    const motherAnimals = animals.filter((animal) => animal.isMother);
    const { data: canals, status } = useSelector((state) => state.canals);
    const { data: users } = useSelector((state) => state.canalUsers);
    const canalInfos = canals.find((asso) => asso.id === canalId);
    const { data: citiesSector } = useSelector((state) => state.citiesSector);
    const { data: subSectors } = useSelector((state) => state.subSectors);
    const associationsValidate = useSelector((state) => state.associations?.associationsDetails);
    const [name, setName] = useState('');
    const [identification, setIdentification] = useState(false);
    const [numberIdentification, setNumberIdentification] = useState('');
    const [isBelonged, setBelong] = useState(false);
    const [isSterilize, setSterilize] = useState(false);
    const [isTrapped, setTrap] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
    const [colors, setColor] = useState([]);
    const [patterns, setPattern] = useState([]);
    const [hairs, setHair] = useState([]);
    const [races, setRace] = useState([]);
    const [isSick, setDisease] = useState('');
    const [diseases, setDiseases] = useState('');
    const [particularities, setParticularities] = useState('');
    const [commentSignal, setCommentSignal] = useState('');
    const [city, setCity] = useState('');
    const [cityId, setCityId] = useState('');
    const [subSector, setSubSector] = useState('');
    const [subSectorId, setSubSectorId] = useState('');
    const [sexAnimal, setSex] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTrappedDate, setSelectedTrappedDate] = useState('');
    const [wasIdentified, setWasIdentified] = useState('');
    const [selectedSterilizationDate, setSelectedSterilizationDate] = useState('');
    const [dateIdentification, setDateIdentification] = useState(null);
    const [isFamily, setFamily] = useState(null);
    const [motherId, setMotherId] = useState('');
    const [situationCat, setSituationCat] = useState('');
    const [mother, setMother] = useState(null);
    const [selectedSituationCat, setAnimalSituation] = useState(null);
    const [situationId, setSituationId] = useState(null);
    const [fatherId, setFatherId] = useState(null);
    const [isMother, setIsMother] = useState(null);
    const [requestInProcess, setRequestInProcess] = useState(null);
    const [previewImage, setPreviewImage] = useState('');
    const [imageUrlCat, setImageUrl] = useState('');
    const [image, setImage] = useState(null);
    const [documents, setDocuments] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [showSubSectors, setShowSubSectors] = useState('');
    const [associationId, setAssociationId] = useState(null);
    const [selectedAssociationCat, setAnimalAssociation] = useState(null);
    const [markers, setMarkers] = useState([]);

    const triggerFileUpload = () => {
        fileInputRef.current.click();
    };


    useEffect(() => {

        dispatch(fetchAnimalsByCanal(canalId))
        dispatch(fetchAssociationsDetails([canalId]))

    }, [dispatch, canalId]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setRequestInProcess(true);
        let imageUrl = {};

        if (imageUrlCat) {
            imageUrl = await uploadImage(imageUrlCat);
        }

        const data = {
            userCreatorId: uid,
            addedDate: formattedTodayDate,
            canalId: canalId,
            canalName: canalInfos.name,
            name: name,
            sex: sexAnimal,
            age: selectedDate,
            hasIdNumber: identification,
            idNumber: numberIdentification,
            dateIdentification: dateIdentification,
            isBelonged: isBelonged,
            isSterilise: isSterilize,
            isTrapped: isTrapped,
            trappedDate: selectedTrappedDate && isTrapped ? selectedTrappedDate : !selectedTrappedDate && isTrapped ? formattedTodayDate : '',
            sterilizationDate: selectedSterilizationDate && isSterilize ? selectedSterilizationDate : !selectedSterilizationDate && !isSterilize ? formattedTodayDate : '',
            isSick: isSick,
            colors: colors,
            patterns: patterns,
            races: races,
            hairs: hairs,
            diseases: diseases,
            particularities: particularities,
            citySectorId: cityId,
            citySectorName: city,
            subSectorId: subSectorId,
            subSectorName: subSector,
            image: imageUrl,
            isFamily: isFamily,
            isMother: isMother,
            motherAppId: motherId,
            mother: mother,
            fatherAppId: fatherId,
            situationCat: selectedSituationCat,
            signal: false,
            published: true,
            commentSignal: commentSignal,
            addedAsStray: wasIdentified ? true : false,
            linkedAssociationId: associationId,
            isLinkedToAnAssociation: selectedAssociationCat ? true : false,
            associationName: selectedAssociationCat,
            location: markers.length ? markers[0] : null
        };
        const subject = 'Un nouvel animal a été ajouté : ' + data.name;
        const message = 'Bonjour, un nouvel animal est disponible, ' + data.name + ' a été ajouté dans le canal ' + canalInfos.name;
        const userIds = [];
        // const authorized = true;
        users.forEach(user => {
            if (user.licenceStatus !== 'inactive' || (user.isAdmin === true && user.notificationsAdminPreferences?.[canalId] === true)) {
                userIds.push(user?.id)
            }
        });

        try {
            const createdAnimal = await dispatch(addAnimal(data));
            const animalId = createdAnimal.payload.id;
            if (documents.length > 0) {
                const uploadedDocuments = await Promise.all(documents.map(uploadSingleFile, name));
                await dispatch(
                    addDocumentToAnimal({
                        animalId: animalId,
                        documents: uploadedDocuments,
                    })
                );
            }

            dispatch(createAndSendNotification({ userIds, message, subject }));


            Swal.fire({
                icon: 'success',
                title: `${t('modal.animalNowAvailable')}`,
                text: `${t('modal.creationOfAnimalWasASuccess')}`,
                confirmButtonColor: '#2F4F4F',
                confirmButtonText: 'OK',
                showClass: {
                    popup: 'animate__animated animate__fadeIn'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOut'
                }
            })
            setRequestInProcess(false);
            navigate(-1, { from: navigate.location });


        } catch (error) {
            console.error('Error adding object: ', error);
            setRequestInProcess(false);
            Swal.fire({
                icon: 'error',
                title: `${t('modal.errorWhenAddingTheAnimalTitle')}`,
                text: `${t('modal.errorWhenAddingTheAnimal')} ${error}`,
                confirmButtonColor: '#2F4F4F',
                confirmButtonText: 'OK',
                showClass: {
                    popup: 'animate__animated animate__fadeIn'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOut'
                }
            })
        }
    };

    const handleObjectFormCityChange = async (id, name) => {
        setCity(name);
        setCityId(id);

    };

    const handleObjectFormSubSectorChange = async (id, name) => {
        setSubSector(name);
        setSubSectorId(id);

    };


    const handleObjectFormAnimalChange = async (id, name) => {
        setMother(name);
        setMotherId(id);
    };

    const handleObjectFormAnimalSituationChange = async (id, situation) => {
        setAnimalSituation(situation);
        setSituationId(id);
    };


    const handleMarkerAdded = (marker) => {
        setMarkers([marker]); // Garde seulement un marqueur à la fois
    };


    const handleRobeChange = (event) => {
        if (event.target.checked) {
            setColor([...colors, event.target.value]);
        } else {
            setColor(colors.filter((color) => color !== event.target.value));
        }
    };

    const handlePatternChange = (event) => {
        if (event.target.checked) {
            setPattern([...patterns, event.target.value]);
        } else {
            setPattern(patterns.filter((pattern) => pattern !== event.target.value));
        }
    };


    const handleHairChange = (event) => {
        if (event.target.checked) {
            setHair([...hairs, event.target.value]);
        } else {
            setHair(hairs.filter((hair) => hair !== event.target.value));
        }
    };

    const handleRaceChange = (event) => {
        if (event.target.checked) {
            setRace([...races, event.target.value]);
        } else {
            setRace(races.filter((race) => race !== event.target.value));
        }
    };

    const extractAndMapAttributes = (inputStr, mapping) => {
        if (inputStr) {
            const attributes = inputStr.toLowerCase().split(/\s+et\s+/i);
            return attributes
                .map(attr => {
                    const normalizedAttr = attr.trim(); // Supprimez les espaces inutiles
                    return mapping[normalizedAttr] || attr; // Utilisez la clé normalisée pour trouver la correspondance
                })
                .filter(Boolean);
        }
        return [];
    };


    const handleAnalysisComplete = (extractedData) => {

        const formattedBirthdate = extractedData.dateNaissance.split('/').reverse().join('-');
        const formattedIdentificationDate = extractedData.dateIdentification.split('/').reverse().join('-');
        const matchedRobe = extractAndMapAttributes(extractedData.robe, COLORS);
        const matchedTypeRacial = extractAndMapAttributes(extractedData.typeRacial, RACES); // Assurez-vous d'avoir une liste similaire pour les types raciaux
        const matchedHair = extractAndMapAttributes(extractedData.poil, HAIRS); // Assurez-vous d'avoir une liste similaire pour les types raciaux

        // Ici, vous pouvez mettre à jour les états avec les données extraites
        setName(extractedData.nomUsage !== 'non trouvé' ? extractedData.nomUsage : '');
        setNumberIdentification(extractedData.insert !== 'Non trouvé' ? extractedData.insert : extractedData.tatouage ? extractedData.tatouage : '');
        if (extractedData.insert || extractedData.tatouage) {
            setIdentification(true)
        }
        setSterilize(extractedData.sterilise === 'OUI' ? true : extractedData.sterilise === 'NON' ? false : '');
        setSex(extractedData.sexe === 'MALE' ? 'Mâle' : extractedData.sexe === 'FEMELLE' ? 'Femelle' : '')
        setColor(matchedRobe !== 'non trouvé' ? matchedRobe : []);
        setRace(matchedTypeRacial !== 'non trouvé' ? matchedTypeRacial : []);
        setHair(matchedHair !== 'non trouvé' ? matchedHair : []);
        setDateIdentification(formattedIdentificationDate || '');
        setSelectedSterilizationDate(formattedIdentificationDate || '');
        setSelectedDate(formattedBirthdate || '');
    };


    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            // Créer un aperçu
            const previewUrl = URL.createObjectURL(file);
            setPreviewImage(previewUrl); // setPreviewImage est une fonction de useState pour stocker l'URL de l'aperçu
            setImageUrl(file); // setImageUrl est une fonction de useState pour stocker l'URL de l'image sur Firebase
        }
    };


    const handleObjectFormAnimalAssociationChange = async (id, situation) => {
        setAnimalAssociation(situation);
        setAssociationId(id);

        // console.log('id', id)
        // console.log('situation', situation)
        const isLinkedToAssoc = !!id;  // true si id n'est pas null, false sinon
        const updateData = {
            linkedAssociationId: id,
            isLinkedToAnAssociation: isLinkedToAssoc,
            associationName: situation
        };
    };

    const associationsInCanal = useMemo(() => {
        return users.filter(user => user.isAssociation);
    }, [users]); // Assurez-vous que canalId est bien la dépendance corr

    useEffect(() => {
        if (associationsInCanal.length > 0) {
            // On extrait soit le RNA soit le SIRET, en fonction de leur existence
            const ids = associationsInCanal.map(assoc => assoc.rnaAsso || assoc.siretAsso).filter(id => id);
            // console.log("ids (RNA ou SIRET) : ", ids)
    
            if (ids.length > 0) {
                dispatch(fetchAssociationsDetails(ids)); // Tu envoies les IDs trouvés à la fonction de dispatch
            }
        }
    }, [dispatch, associationsInCanal]); // Utilisation sûre car associationsInCanal est stabilisé par useMemo


    // Effet pour mettre à jour la date de capture lorsque isTrapped change
    useEffect(() => {
        if (isTrapped) {
            setSelectedTrappedDate(formattedTodayDate);
        } else {
            setSelectedTrappedDate(''); // Réinitialiser si isTrapped est false
        }
    }, [isTrapped]);



    useEffect(() => {
        if (cityId) {
            setShowSubSectors(true);
            dispatch(fetchSubSectors(cityId))
        } else {
            setShowSubSectors(''); // Réinitialiser si isTrapped est false
        }
    }, [cityId]);

    // Effet pour mettre à jour la date de capture lorsque isTrapped change
    useEffect(() => {
        if (isSterilize) {
            setSelectedSterilizationDate(formattedTodayDate);
        } else {
            setSelectedSterilizationDate('');
        }
    }, [isSterilize]);



    if (status === 'loading') {
        return <LoadingPage isLoading={true} />;
    }

    return (
        <div className='kappze-page kappze-paw'>
            <LoadingPage isLoading={false} />
            <BackButton needsBackground={false} />



            <div className='add-cat-page_section'>
                <form onSubmit={handleSubmit} className='authFormCat'>
                    {/* <div className="header-add-cat" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>

                        <h2 style={{ color: 'white', textAlign: 'center' }}>{t('addCat')}</h2>
                        <img src={CatNotFound} style={{ maxWidth: '100px' }} />
                    </div> */}

                    <div className="create-animal-img-name" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', rowGap: '1rem' }}>
                        <div className="image-upload-container">
                            {previewImage ? (
                                <img src={previewImage} alt="Aperçu du chat" className="avatar-preview" onClick={triggerFileUpload} />
                            ) : (
                                <div className="avatar-placeholder" onClick={triggerFileUpload}>
                                    {/* Ici, vous pouvez mettre un icône ou un texte invitant à uploader une image */}
                                    <img src={imgIconWhite} style={{ width: '30px' }} />
                                </div>
                            )}
                            <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} ref={fileInputRef} />
                        </div>


                        <label htmlFor="name" style={{ display: 'none' }}>{t('name')} </label>
                        <input required type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} className="auth-input auth-input-name" placeholder="Nom de l'animal *" />
                    </div>

                    {/* <p>{t('theMoreInformationYouFillInTheMoreAccurateTheDataWillBe')}.</p> */}


                    <div className="adding-cat-heading-informations" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', rowGap: '2rem' }}>
                        <div className='select-localization'>
                            <CityAndSectorSelect
                                idPrefix="modal"
                                cities={citiesSector}
                                selectedCityId={cityId}
                                onCityChange={handleObjectFormCityChange}
                            />

                            {(cityId && showSubSectors && subSectors.length) ? (
                                <CityAndSectorSelect
                                    idPrefix="modal"
                                    cities={subSectors}
                                    selectedCityId={subSectorId}
                                    onCityChange={handleObjectFormSubSectorChange}
                                    selectType="subSector"
                                />
                            ) : (null)}


                            <AssociationSelect
                                associations={associationsValidate}
                                idPrefix="modal"
                                animals={motherAnimals}
                                currentAssociation={null}
                                selectedAssociationCat={associationId}
                                onAssociationChange={handleObjectFormAnimalAssociationChange}
                                needsLabel={true}
                                isEditMode={true}
                                currentSituationAnimal={null}
                                placeholder={associationId ? associationId : "Choisir une association"}
                            />







                            <div className='select-trapped' style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', rowGap: '1rem' }}>

                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', alignItems: 'center' }}>
                                    <label htmlFor="trapped" style={{ fontWeight: '600' }}>A été trappé</label>
                                    <label className="switch">
                                        <input type="checkbox" checked={isTrapped} onChange={(e) => setTrap(e.target.checked)} />
                                        <span className="slider round"></span>
                                    </label>
                                    <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-isTrapped" />


                                    <Tooltip id="my-tooltip-isTrapped" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', maxWidth: '248px' }}>
                                        <div style={{ backgroundColor: '#122' }}>
                                            <h3>{t('capture')}</h3>
                                            <p>Cette donnée peut être ajoutée ou modifiée ultérieurement.</p>
                                            <ul>
                                                <li>Elle permet de définir si ce chat est issu d'une capture.</li>
                                                <li>Cette donnée est à cocher si l'animal a été capturé par le passé.</li>
                                            </ul>
                                        </div>
                                    </Tooltip>
                                </div>

                                {isTrapped && (
                                    <>
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem' }}>
                                        <label htmlFor="trappe">Date de capture (Par défaut : aujourd'hui)</label>
                                        <input type="date" id="trappe" value={isTrapped ? selectedTrappedDate : ''} onChange={(e) => setSelectedTrappedDate(e.target.value)} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label style={{textDecoration: 'underline'}} htmlFor="mother">Etait identifié lors de sa capture ?</label>

                                <label className="switch">
                                    <input type="checkbox" checked={wasIdentified} onChange={(e) => setWasIdentified(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>

                            </div>
                                    </>
                                )}


                            </div>
                        </div>





                        <div className="ia-analyzer" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{ textAlign: 'center' }}>Vous disposez du formulaire de traçabilité de l'I-CAD ? <br />L'IA vous pré-rempli le formulaire. </p>

                            <div className="ia-analyzer" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-Start', columnGap: '1rem' }}>
                                <ImageAnalyzer onAnalysisComplete={handleAnalysisComplete} />
                            </div>
                        </div>








                    </div>


                    <div className='select-trapped' style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', rowGap: '1rem' }}>

                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', alignItems: 'center' }}>

                            <label htmlFor="trapped" style={{ fontWeight: '600' }}>Ajouter la localisation</label>
                            <label className="switch">
                                <input type="checkbox" checked={showLocation} onChange={(e) => setShowLocation(e.target.checked)} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        {showLocation && (
                            <div><DynamicMap communeCode={canalInfos?.codeCommune} markers={markers} onMarkerAdded={handleMarkerAdded} /></div>
                        )}
                    </div>





                    <div className="adding-cat-informations">

                        <div className="general-cat-informations bloc-cat-informations">
                            <h3 style={{ margin: '0', fontWeight: '600', marginBottom: '1rem' }}>{t('generalInformations')}</h3>
                            {/* <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem' }}>
                                <label htmlFor="name">{t('name')} </label>
                                <input required type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} className="auth-input" />
                            </div> */}
                            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem', flexWrap: 'wrap', rowGap: '1rem' }}>
                                <label className='label-radio-animal'>{t('sex')} : </label>
                                <div className='radio-container-animal'>
                                    <input
                                        type="radio"
                                        id="male"
                                        name="sex"
                                        value="Mâle"
                                        checked={sexAnimal === 'Mâle'}
                                        onChange={(e) => setSex(e.target.value)}
                                    />
                                    <label htmlFor="male" className={`radio-text-animal ${sexAnimal === 'Mâle' && 'selected-radio-text-animal'}`}>{t('male')}</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        id="female"
                                        name="sex"
                                        value="Femelle"
                                        checked={sexAnimal === 'Femelle'}
                                        onChange={(e) => setSex(e.target.value)}
                                    />
                                    <label htmlFor="female" className={`radio-text-animal ${sexAnimal === 'Femelle' && 'selected-radio-text-animal'}`}>{t('female')}</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        id="unknown"
                                        name="sex"
                                        value="Inconnu"
                                        checked={sexAnimal === 'Inconnu'}
                                        onChange={(e) => setSex(e.target.value)}
                                    />
                                    <label htmlFor="unknown" className={`radio-text-animal ${sexAnimal === 'Inconnu' && 'selected-radio-text-animal'}`}>{t('sexUnknown')}</label>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem' }}>
                                <label htmlFor="sterilise">{t('theCatIsSterilized')} </label>
                                <label className="switch">
                                    <input type="checkbox" checked={isSterilize} onChange={(e) => setSterilize(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>


                                {isSterilize && (
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem' }}>
                                        <label htmlFor="trappe">Date de stérilisation (Par défaut : aujourd'hui)</label>
                                        <input type="date" id="trappe" value={isSterilize ? selectedSterilizationDate : ''} onChange={(e) => setSelectedSterilizationDate(e.target.value)} />
                                    </div>
                                )}


                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem' }}>
                                <label htmlFor="birthdate">{t('dateOfBirth')} </label>
                                <input type="date" id="birthdate" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
                            </div>
                            <OpenableSection title='Couleurs et motifs' customClass="create-animal-page">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {/* <label >Couleurs de la robe </label> */}
                                    <ColorSelect selectedColors={colors} onChange={handleRobeChange} />
                                    <p style={{ color: 'black', fontWeight: '500' }}>Motifs sur le pelage</p>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {/* <label >Couleurs de la robe </label> */}
                                        <PatternSelect selectedPatterns={patterns} onChange={handlePatternChange} />

                                    </div>
                                </div>
                            </OpenableSection>

                            <OpenableSection title={t('hairType')} customClass="create-animal-page">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <HairSelect selectedColors={hairs} onChange={handleHairChange} />
                                </div>
                            </OpenableSection>
                            <OpenableSection title={t('breed')} customClass="create-animal-page">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <RaceSelect selectedRaces={races} onChange={handleRaceChange} />
                                </div>
                            </OpenableSection>

                            {/* <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="image">Image </label>
                                <input type="file" id="image" onChange={(e) => setImage(e.target.files[0])} className="auth-input" />
                            </div> */}
                        </div>


                        <div className="id-cat-informations bloc-cat-informations">
                            <h3 style={{ margin: '0', fontWeight: '600', marginBottom: '1rem' }}>{t('identification')}</h3>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="identification">{t('isTheCatIdentified')} ?  </label>
                                <label className="switch">
                                    <input type="checkbox" checked={identification} onChange={(e) => setIdentification(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="dateIdentification">{t('dateOfIdentification')} </label>
                                <input type="date" id="dateIdentification" value={dateIdentification} onChange={(e) => setDateIdentification(e.target.value)} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="numeroIdentification">{t('identificationNumber')} (puce ou tatouage)</label>
                                <input type="text" id="numeroIdentification" value={numberIdentification} onChange={(e) => setNumberIdentification(e.target.value)} className="auth-input" />
                            </div>
                            {/* Bloc 2 */}


                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="appartenance">{t('theCatHasAnOwner')} (identifié ou non) </label>

                                <label className="switch">
                                    <input type="checkbox" checked={isBelonged} onChange={(e) => setBelong(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>




                        <div className="relationship-cat-informations bloc-cat-informations">
                            <h3 style={{ margin: '0', fontWeight: '600', marginBottom: '1rem' }}>{t('theCatIsLinkedToAFamily')}<img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-family" />
                            </h3>
                            <Tooltip id="my-tooltip-family" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', maxWidth: '248px' }}>
                                <div style={{ backgroundColor: '#122' }}>
                                    <h3>Famille</h3>
                                    <p>Cette donnée permet de suivre les portées et construit l'arbre généalogique. Elle est optionnelle sauf si besoins spécifiques.</p>
                                    <ul>
                                        <li>Si le chat est lié à une famille, il s'affichera dans le listing des suggestions sur une page de chat.</li>
                                        <li>Définir le chat comme une mère permet de créer l'arbre généalogique à partir de ce chat.</li>
                                        <li>Si vous connaissez déjà la mère, le chat sera directement lié à sa mère.</li>
                                    </ul>
                                </div>
                            </Tooltip>

                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="famille">{t('theCatIsLinkedToAFamily')} </label>

                                <label className="switch">
                                    <input type="checkbox" checked={isFamily} onChange={(e) => setFamily(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="mother">{t('theCatIsAMother')} </label>

                                <label className="switch">
                                    <input type="checkbox" checked={isMother} onChange={(e) => setIsMother(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>

                            </div>

                            <MotherSelect
                                idPrefix="modal"
                                animals={motherAnimals}
                                selectedAnimalId={motherId}
                                onAnimalChange={handleObjectFormAnimalChange}
                                needsLabel={true}
                                isEditMode={true}
                            />

                        </div>



                        <div className="other-cat-informations bloc-cat-informations">
                            <h3 style={{ margin: '0', fontWeight: '600', marginBottom: '1rem' }}>{t('other')}</h3>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="disease">{t('doesTheCatSeemsSick')}? </label>

                                <label className="switch">
                                    <input type="checkbox" checked={isSick} onChange={(e) => setDisease(e.target.checked)} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="maladies">{t('diseases')} </label>
                                <input type="text" id="maladies" value={diseases} onChange={(e) => setDiseases(e.target.value)} className="auth-input" />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="particularites">{t('specialFeatures')} </label>
                                <input type="text" id="particularites" value={particularities} onChange={(e) => setParticularities(e.target.value)} className="auth-input" />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <SituationSelect
                                    idPrefix="modal"
                                    animals={motherAnimals}
                                    selectedSituationCat={situationCat}
                                    onSituationChange={handleObjectFormAnimalSituationChange}
                                    needsLabel={true}
                                    isEditMode={true}
                                />
                            </div>

                        </div>


                        <div className="other-cat-informations bloc-cat-informations">
                            <h3 style={{ margin: '0', fontWeight: '600', marginBottom: '1rem' }}>{t('documents')}</h3>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', columnGap: '1rem', rowGap: '1rem' }}>
                                <label htmlFor="documents">{t('addDocuments')} </label>
                                <input type="file" id="documents" multiple onChange={(e) => setDocuments([...e.target.files])} className="auth-input" />
                            </div>
                        </div>

                    </div>

                    <button className="button-general" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: '0' }} type="submit">{t('add')}
                        <img style={{ width: '28px', marginLeft: '5px' }} src={iconpaw} alt="Icon Paw" className="button-icon" />
                        <img style={{ width: '10px', margin: '0px' }} src={iconadd} alt="Icon Add" className="button-icon" /></button>
                    {requestInProcess && <div className="loaderPaymentAddCat"></div>}
                </form>
            </div>
        </div>
    );
};

export default ObjectForm;



